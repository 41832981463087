<template>
  <main class="main">
    <section class="technologies-area p-80">
      <section class="technologies">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <section class="technologies-title">
                <h2>{{$t('technologies.title')}}</h2>
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/html-css-logo.png" title="HTML/CSS" alt="HTML/CSS">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/js-logo.png" title="Javascript" alt="Javascript">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/vue-logo.png" title="Vue" alt="Vue">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/babel-logo.png" title="Babel" alt="Babel">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/bootstrap-logo.png" title="Bootstrap" alt="Bootstrap">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/jquery-logo.png" title="Jquery" alt="Jquery">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/mysql-logo.png" title="MySQL" alt="MySQL">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/php-logo.png" title="PHP" alt="PHP">  
              </section>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 ">
              <section class="technologies-img">
                <img src="../assets/img/sass.png" title="SaSS" alt="SaSS">  
              </section>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import setDocumentTitle from '../util'
export default {
  name: 'Technologies',
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.technologies"))
      },
      { immediate: true }
    )
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.technologies-area{
  @include views-general;
  .technologies{
    @include views;
    .technologies-title{
      @include section-title;
    }
    .technologies-img{
      @include img-settings;
    }
    img{
      &:hover{
        filter: grayscale(100%);
        transition: 0.5s;
      }
    }
  }
}

</style>